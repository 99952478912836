import React from "react";

const arrow_down = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.6673 16.9987L30.7298 14.0612L19.084 25.6862L19.084 0.332031L14.9173 0.332031L14.9173 25.6862L3.29232 14.0404L0.333984 16.9987L17.0007 33.6654L33.6673 16.9987Z" fill="#4A4B5A"/>
        </svg>
    )
}

export default {arrow_down};